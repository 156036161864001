<template>
  <div>
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('series') }}</label>
          <b-button-group style="width: 100%;">
            <v-select
              v-model="filter.series_code"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rotation.seriesList"
              :reduce="val => val.code"
              label="name"
              style="width: 60%"
              @input="setRotationSelected"
            />
            <b-button
              variant="outline-primary"
              :disabled="rotation.selectedSeries === null || rotation.previousSeriesCode === null"
              style="margin-left: 5px; border-top-left-radius: 0.358rem; border-bottom-left-radius: 0.358rem;"
              @click="loadData(rotation.previousSeriesCode)"
            >
              {{ $t('previous') }}
            </b-button>
            <b-button
              variant="outline-primary"
              :disabled="rotation.selectedSeries === null && rotation.currentSeriesCode === null"
              @click="loadData(rotation.currentSeriesCode)"
            >
              {{ $t('now') }}
            </b-button>
            <b-button
              variant="outline-primary"
              :disabled="rotation.selectedSeries === null || rotation.nextSeriesCode === null"
              @click="loadData(rotation.nextSeriesCode)"
            >
              {{ $t('next') }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        small
        responsive
        :items="rotation.levelList"
        :fields="fields"
        class="mb-0"
      >
        <template #cell(level)="data">
          {{ data.item.name }}
        </template>
        <template #cell(rotation)="data">
          <b-button-group>
            <b-form-radio-group
              id="btn-radios-1"
              v-model="rotation.formData.rotations[data.item.id]['number']"
              button-variant="outline-primary"
              :options="data.item.rotationOption"
              buttons
              name="radios-btn-default"
            />
          </b-button-group>
        </template>
      </b-table>
    </b-card>

    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              :disabled="rotation.selectedSeries === null"
              variant="primary"
              @click="register"
            >
              {{ $t('register') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BButtonGroup,
  BButton,
  BTable,
  BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Rotation from '@/models/Rotation'

export default {
  name: 'AdminRotation',
  components: {
    BRow,
    BCol,
    BCard,
    BButtonGroup,
    BButton,
    vSelect,
    BTable,
    BFormRadioGroup,
  },
  data() {
    return {
      fields: [
        'level',
        'rotation',
      ],
      filter: {
        series_code: '',
      },
      rotation: {
        previousSeriesCode: null,
        currentSeriesCode: null,
        nextSeriesCode: null,
        selectedSeries: null,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(seriesCode = null) {
      if (seriesCode !== null) {
        this.filter.series_code = seriesCode
      }
      const response = await Rotation.view(this.filter)
      this.rotation = response.data
      this.filter.series_code = this.rotation.seriesCode
    },
    setRotationSelected(value) {
      this.loadData(value)
    },
    async register() {
      try {
        const response = await Rotation.store(this.rotation.formData)

        this.$swal({
          title: 'Success!',
          text: response.data,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.loadData()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
