import axios from '@/libs/axios'

class Rotation {
  static store(params) {
    return axios.post('admin/rotation/store', params)
  }

  static view(params) {
    return axios.post('admin/rotation/view', params)
  }
}

export default Rotation
